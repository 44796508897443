import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DebugButton } from './DebugButton';
import { EnvList } from './EnvList';

export const DebugTools = () => {
  const [debug, setDebug] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('debug')) {
      setDebug(true);
    }
  }, []);

  if (!debug) {
    return null;
  }

  if (opened) {
    return (
      <Container>
        <EnvList />
        <DebugButton onClick={() => setOpened(false)} />
      </Container>
    );
  } else {
    return <DebugButton onClick={() => setOpened(true)} />;
  }
};

const Container = styled.div`
  z-index: 10000;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fffe;
`;
