import { startTransition, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Typography } from 'antd';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { Button, ErrorText } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { EnterMnemonicPhrase } from '@/widgets/mnemonic-phrase/enter-mnemonic-phrase';
const cryptoCore = new CryptoCore();

const MainPageForExportedMode = () => {
  const { push } = useRouter();
  const { inputPhrase: mnemonicPhrase } = useMnemonicPhraseStore();
  const { setKeyPair } = useKeyPairStore();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [pubKey, setPubKey] = useState('');

  useEffect(() => {
    const fetchPubKey = async () => {
      const selfUrl = process.env.NEXT_PUBLIC_SELF_URL_BASE as string;
      const url = `${selfUrl}/database/database.json`;

      const response = fetch(url);
      const json = await (await response).json();
      return setPubKey(json?.mainUserData?.publicKey || '');
    };
    fetchPubKey();
  }, []);

  const savePhrase = async () => {
    if (!mnemonicPhrase.trim()) {
      throw new Error('Please enter mnemonic');
    }

    const { publicKey, privateKey } =
      await cryptoCore.mnemonic.mnemonicToKeyPair(mnemonicPhrase.trim());
    if (publicKey !== pubKey) {
      console.log(publicKey, pubKey);
      throw new Error('Wrong mnemonic phrase!');
    }
    setKeyPair({ privateKey, publicKey });
    await push(
      {
        pathname: '/dashboard',
      },
      undefined,
      { shallow: true },
    );
  };

  const load = () =>
    savePhrase().catch((e) => {
      setLoading(false);
      setError(e.message);
    });

  useEffect(() => {
    if (loading) load();
  }, [loading]);

  const handleSave = () => startTransition(() => setLoading(true));

  return (
    <Row
      justifyContent="center"
      paddingTop={35}
      paddingBottom={20}
      width="100%"
    >
      <Row
        maxWidth={390}
        width="100%"
        paddingX={37}
        direction="column"
        justifyContent="space-between"
      >
        <div>
          <Typography.Title level={3}>Welcome to My Hub</Typography.Title>
          <Typography.Title level={4} style={{ marginTop: '20px' }}>
            Enter your Owner.One signup mnemonic phrase
          </Typography.Title>

          <Container
            marginTop={57}
            onClick={loading ? undefined : () => setError('')}
          >
            <EnterMnemonicPhrase />
          </Container>
        </div>
        <Row alignCenter justifyContent={'center'} marginTop={10}>
          {error ? <ErrorText>{error}</ErrorText> : null}
        </Row>
        <Container marginTop={77}>
          <Button text="Confirm" onClick={handleSave} disabled={loading} />
        </Container>
      </Row>
    </Row>
  );
};

export default MainPageForExportedMode;
