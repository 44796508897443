import { RegistrationStepsType } from 'src/features/registration';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { IQuestion } from '@/_pages/auth/components/account-security/questions';
import { TwoFactorQuestionPlainObject } from '@/shared/generated/graphql';

type firstStepDataType = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

type FirstStepBeneficiaryData = {
  email: string;
  password: string;
};

export type twoFactorQuestionsType = {
  question: string;
  answer: string;
};

type RegistrationStoreType = {
  beneficiaryJWT?: string | undefined;
  currentStep: RegistrationStepsType;
  firstStepData: firstStepDataType;
  firstStepBeneficiaryData: FirstStepBeneficiaryData;
  publicKey: string;
  twoFactorQuestions: IQuestion[];
  twoFactorQuestionsAndAnswers: TwoFactorQuestionPlainObject[];
  setCurrentStep: (currentStep: RegistrationStepsType) => void;
  setFirstStepData: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => void;
  setFirstStepBeneficiaryData: (email: string, password: string) => void;
  setPublicKey: (publicKey: string) => void;
  setTwoFactorQuestions: (questions: IQuestion[]) => void;
  setTwoFactorQuestionsAndAnswers: (
    twoFactorQuestions: twoFactorQuestionsType[],
  ) => void;
  setBeneficiaryJWT: (jwt: string | undefined) => void;
  resetStorage: () => void;
  emailPin: string;
  setEmailPin: (pin: string) => void;
};

export const useRegistrationStore = create(
  persist<RegistrationStoreType>(
    (set) => ({
      currentStep: '1',
      beneficiaryJWT: undefined,
      firstStepData: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
      firstStepBeneficiaryData: {
        email: '',
        password: '',
      },
      publicKey: '',
      twoFactorQuestions: [],
      twoFactorQuestionsAndAnswers: [],
      emailPin: '',
      setCurrentStep: (currentStep: RegistrationStepsType): void =>
        set(() => ({
          currentStep: currentStep,
        })),
      setBeneficiaryJWT: (jwt: string | undefined): void =>
        set(() => ({
          beneficiaryJWT: jwt,
        })),
      setFirstStepData: (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
      ): void =>
        set(() => ({
          firstStepData: {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
          },
        })),
      setFirstStepBeneficiaryData: (email: string, password: string): void =>
        set(() => ({
          firstStepBeneficiaryData: {
            email: email,
            password: password,
          },
        })),
      setPublicKey: (publicKey: string): void =>
        set(() => ({
          publicKey: publicKey,
        })),
      setTwoFactorQuestions: (questions: IQuestion[]): void =>
        set(() => ({
          twoFactorQuestions: questions,
        })),
      setTwoFactorQuestionsAndAnswers: (
        twoFactorQuestionsAndAnswers: twoFactorQuestionsType[],
      ): void =>
        set(() => ({
          twoFactorQuestionsAndAnswers: twoFactorQuestionsAndAnswers,
        })),
      resetStorage: (): void =>
        set({
          currentStep: '1',
          firstStepData: {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
          },
          publicKey: '',
          twoFactorQuestions: [],
          twoFactorQuestionsAndAnswers: [],
        }),
      setEmailPin: (pin: string): void =>
        set(() => ({
          emailPin: pin,
        })),
    }),
    {
      name: 'register-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
