import { create } from 'zustand';

export interface ILoginStore {
  whoamiPublicKey: string | null | undefined;
  setWhoamiPublicKey: (whoamiPublicKey: string | null | undefined) => void;
  clear: () => void;
}

export const useLoginStore = create<ILoginStore>((set) => ({
  whoamiPublicKey: undefined,
  setWhoamiPublicKey: (whoamiPublicKey): void => {
    set({ whoamiPublicKey });
  },
  clear: (): void =>
    set({
      whoamiPublicKey: undefined,
    }),
}));
