import { FC } from 'react';
import styled from 'styled-components';

export const DebugButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Container onClick={onClick}>D</Container>
);

const Container = styled.div`
  z-index: 10000;
  position: fixed;
  left: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 black;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
`;
