import { ComponentProps, PropsWithChildren } from 'react';
import { Typography, TypographyProps } from 'antd';

const { Title: AntTitle } = Typography;

export const Title = ({
  children,
  ...props
}: PropsWithChildren<Partial<ComponentProps<TypographyProps['Title']>>>) => (
  <AntTitle style={{ fontFamily: 'Argent CF' }} {...props}>
    {children}
  </AntTitle>
);
